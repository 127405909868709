import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./Style.css";
import axios from "axios";
import {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonPage,
} from "@ionic/react";

export default class Login extends Component<any, any> {
    signupFormRef: React.Ref<HTMLFormElement>;

    constructor(props: {}) {
        super(props);

        this.props.history.listen((location, action) => {
            console.log("history", location);
            if (location.pathname == "/login") {
                console.log("reset state here");
                this.state = {
                    email: null,
                    password: null,
                    token: JSON.parse(
                        window.localStorage.getItem("token") as string
                    ),
                    settings: {
                        success: false,
                    },
                    error: false,
                };

                this.setState(this.state);
                window.location.reload();
            }
        });

        this.state = {
            email: null,
            password: null,
            token: JSON.parse(window.localStorage.getItem("token") as string),
            settings: {
                success: false,
            },
            error: false,
        };
        this.signupFormRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.token) {
            //console.log('hat token', this.state.token)
        }
    }

    componentDidUpdate(prevProps) {}

    onSignup() {
        let vm = this;

        axios
            .post(
                "https://fi-boarding.h-och.ch/api/auth/login",
                {
                    email: this.state.email,
                    password: this.state.password,
                },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                localStorage.setItem("token", JSON.stringify(res.data));

                vm.state.settings.success = true;
                vm.setState(vm.state);
            })
            .catch(function (error) {
                console.log(error);
                vm.setState({ error: true });
            });
    }
    render() {
        if (this.state.token || this.state.settings.success) {
            return <Redirect to="/dashboard" />;
        }

        return (
            <>
                <IonPage>
                    <IonContent>
                        <div className="senn-header tab-2">
                            <a className="hoch-logo" href="/dashboard">
                                <img
                                    src="/img/hoch.png"
                                    style={{ height: "100" }}
                                    width="116px"
                                />
                            </a>
                        </div>

                        {this.state.error && (
                            <p className="content error-message">
                                E-Mail-Adresse und Passwort stimmen nicht
                                überein.
                            </p>
                        )}

                        <form
                            ref={this.signupFormRef}
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.onSignup();
                            }}
                        >
                            <IonList no-lines>
                                <IonItem className="mt-8">
                                    <IonLabel color="primary">E-Mail</IonLabel>
                                    <IonInput
                                        value={this.state.email}
                                        onInput={(e: any) =>
                                            this.setState({
                                                email: e.target.value,
                                            })
                                        }
                                        name="email"
                                        type="text"
                                        required
                                    ></IonInput>
                                </IonItem>
                                <IonItem>
                                    <IonLabel color="primary">
                                        Password
                                    </IonLabel>
                                    <IonInput
                                        value={this.state.password}
                                        onInput={(e: any) =>
                                            this.setState({
                                                password: e.target.value,
                                            })
                                        }
                                        name="password"
                                        type="password"
                                        required
                                    ></IonInput>
                                </IonItem>
                            </IonList>
                            <div className="button-wrapper">
                                <IonButton
                                    expand="full"
                                    style={{ margin: 14 }}
                                    type="submit"
                                >
                                    Login
                                </IonButton>
                            </div>
                            <div className="button-wrapper">
                                <a
                                    href="/registrieren"
                                    style={{ textDecoration: "none" }}
                                >
                                    <IonButton
                                        expand="full"
                                        fill="clear"
                                        style={{ margin: 14 }}
                                    >
                                        Registrieren
                                    </IonButton>
                                </a>
                            </div>
                        </form>
                    </IonContent>
                </IonPage>
            </>
        );
    }
}
