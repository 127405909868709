import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    IonInput,
    IonAvatar,
    IonButton,
} from "@ionic/react";
import React, { useState, useEffect, Component } from "react";
import { Redirect, Route } from "react-router-dom";
import "./Style.css";
import axios from "axios";

import {
    PushNotifications,
    PushNotificationActionPerformed,
    PushNotificationToken,
} from "@capacitor/push-notifications";

import {
    LocalNotifications,
    ILocalNotification,
    ELocalNotificationTriggerUnit,
} from "@awesome-cordova-plugins/local-notifications";

export default class Tab1 extends Component<any, any> {
    constructor(props: {}) {
        super(props);
        this.state = {
            token: JSON.parse(window.localStorage.getItem("token") as string),
            settings: {
                timestamps: [],
                password: {},
                user: {},
                loading: false,
            },
        };
    }

    componentDidMount() {
        console.log("Tab3 Did Mount");
        if (!this.state.token) {
            return;
        }

        this.getUser();
    }

    getUser() {
        let vm = this;

        axios
            .get("https://fi-boarding.h-och.ch/api/v1/user", {
                headers: {
                    Authorization: "Bearer " + this.state.token.access_token,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                this.state.settings.loading = false;
                this.state.settings.success = true;
                this.state.settings.user = res.data;
                console.log(res.data);

                this.setState(this.state);
                this.scheduleNotification();
            })
            .catch(function (error) {
                console.log(error);
                vm.state.settings.error = true;
                window.localStorage.removeItem("token");
                vm.setState(vm.state);
            });
    }

    hasPushNotifications() {
        const permission = LocalNotifications.hasPermission();

        console.log("permission:");
        console.log(permission);
    }
    clearNotifications() {
        LocalNotifications.cancelAll();

        axios
            .post(
                "https://fi-boarding.h-och.ch/api/v1/user",
                { notifications: true },
                {
                    headers: {
                        Authorization:
                            "Bearer " + this.state.token.access_token,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                this.getUser();
            });
    }

    scheduleNotification() {
        LocalNotifications.cancelAll();

        var notifications = [];

        // console.log(this.state.settings.user.notifications);
        this.state.settings.user.notifications.forEach(function (timestamp, i) {
            var notification = {
                id: i,
                title: "Hoch fi-boarding",
                text: "Wo arbeitest du nächste Woche?",
                trigger: { at: new Date(timestamp * 1000) },
            };
            notifications.push(notification);
        });
        // console.log(notifications);
        LocalNotifications.schedule(notifications);
    }
    enablePushnotifications() {
        axios
            .post(
                "https://fi-boarding.h-och.ch/api/v1/user",
                { notifications: false },
                {
                    headers: {
                        Authorization:
                            "Bearer " + this.state.token.access_token,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                this.getUser();
            });

        console.log("enable push notifications");

        const permission = LocalNotifications.requestPermission();

        if (!permission) {
            console.log("no permission");
        } else {
            console.log("permission granted");
        }

        this.scheduleNotification();
    }

    getInitials(string) {
        var names = string.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }

    logout() {
        console.log("logout");
        window.localStorage.removeItem("token");
        this.state.settings.error = true;
        this.setState(this.state);
    }

    handleChange(e) {
        this.state.settings.password.password = e.target.value;
    }

    saveProfile() {
        console.log("save profile");
        this.state.settings.loading = true;
        this.setState(this.state);
        let vm = this;
        const input = {
            ...this.state.settings.user,
            ...this.state.settings.password,
        };

        console.log("input", input);

        axios
            .post("https://fi-boarding.h-och.ch/api/v1/user", input, {
                headers: {
                    Authorization: "Bearer " + this.state.token.access_token,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                this.state.settings.loading = false;
                this.state.settings.success = true;
                this.setState(this.state);
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                }
                vm.state.settings.error = true;
                window.localStorage.removeItem("token");
                vm.setState(vm.state);

                console.log(error.toJSON());
            });
    }

    render() {
        if (!this.state.token || this.state.settings.error) {
            return <Redirect to="/login" push />;
        }

        return (
            <>
                <IonPage>
                    <IonContent>
                        <div className="senn-header tab-2">
                            <a className="senn-logo" href="/dashboard">
                                <img
                                    src="/img/hoch.png"
                                    style={{ height: "100" }}
                                    width="100px"
                                />
                            </a>
                        </div>

                        <div className="content">
                            <div className="profile-header">
                                <IonAvatar className="profile-img">
                                    {this.state.settings.user &&
                                        this.state.settings.user.name && (
                                            <span className="initials">
                                                {this.getInitials(
                                                    this.state.settings.user
                                                        .name
                                                )}
                                            </span>
                                        )}
                                </IonAvatar>
                            </div>

                            <IonItem>
                                <IonLabel position="floating">Name</IonLabel>
                                <IonInput
                                    disabled
                                    value={this.state.settings.user.name}
                                ></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">E-Mail</IonLabel>
                                <IonInput
                                    disabled
                                    value={this.state.settings.user.email}
                                ></IonInput>
                            </IonItem>

                            <IonItem>
                                <IonLabel position="floating">
                                    Passwort
                                </IonLabel>
                                <IonInput
                                    type="password"
                                    onInput={(e) => this.handleChange(e)}
                                ></IonInput>
                            </IonItem>

                            {/* <IonItem>
                <IonLabel position="floating" >Präferenz</IonLabel>
                <IonInput value={(this.state.settings.user.settings && this.state.settings.user.settings.preference) ? this.state.settings.user.settings.preference : null}></IonInput>
              </IonItem> */}

                            {!this.state.settings.loading && (
                                <IonButton
                                    className="mt-8 custom-button"
                                    color="success"
                                    expand="block"
                                    onClick={() => this.saveProfile()}
                                >
                                    Speichern
                                </IonButton>
                            )}

                            {this.state.settings.loading && (
                                <IonButton color="light" expand="block">
                                    Bitte warten...
                                </IonButton>
                            )}

                            {/* <IonButton className="mt-8 custom-button" color="light" expand="block" onClick={() => this.scheduleNotification()}>Benachrichtigungen planen</IonButton>

                              */}

                            {this.state.settings.user.settings &&
                                this.state.settings.user.settings
                                    .disable_notifications && (
                                    <IonButton
                                        className="mt-8 custom-button"
                                        color="light"
                                        expand="block"
                                        onClick={() =>
                                            this.enablePushnotifications()
                                        }
                                    >
                                        Benachrichtigungen erlauben
                                    </IonButton>
                                )}

                            {this.state.settings.user.settings &&
                                !this.state.settings.user.settings
                                    .disable_notifications && (
                                    <IonButton
                                        className="mt-8 custom-button"
                                        color="danger"
                                        expand="block"
                                        onClick={() =>
                                            this.clearNotifications()
                                        }
                                    >
                                        Benachrichtigungen ausschalten
                                    </IonButton>
                                )}

                            <IonButton
                                className="mt-8 custom-button"
                                color="danger"
                                expand="block"
                                fill="clear"
                                href="https://fi-boarding.h-och.ch/konto-loeschen"
                            >
                                Konto löschen
                            </IonButton>

                            {/* <IonButton className="mt-8 custom-button" fill="clear" expand="block" onClick={() => this.hasPushNotifications()}>Benachrichtigungen prüfen</IonButton> */}

                            <IonButton
                                className="mt-8 custom-button"
                                color="medium"
                                expand="block"
                                fill="clear"
                                onClick={() => this.logout()}
                            >
                                Abmelden
                            </IonButton>
                        </div>
                    </IonContent>
                </IonPage>
            </>
        );
    }
}
