import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonIcon,
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import { create } from "ionicons/icons";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "./Style.css";
import SeatMap from "./SeatMap";
import axios from "axios";

const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export default class Tab1 extends Component<any, any> {
    constructor(props: {}) {
        super(props);

        this.props.history.listen((location, action) => {
            if (location.pathname === "/dashboard") {
                this.getUser();
            }
        });

        this.state = {
            token: JSON.parse(window.localStorage.getItem("token") as string),
            settings: {
                maps: null,
                user: null,
                tab: "today",
                today: false,
                showMap: false,
                loading: true,
                edit: false,
                active: null,
                error: false,
                success: false,
            },
            booking: {
                seat: null,
                place: null,
                morning: null,
                afternoon: null,
            },
        };

        this.props.history.listen((location, action) => {
            if (this.props.history.location.search === "?tab=today") {
                // console.log('change tab today constructior', this.props.history.location)
                this.state.settings.tab = "today";
            }
            if (this.props.history.location.search === "?tab=tomorrow") {
                // console.log('change tab TOMORRO constructior', this.props.history.location)
                this.state.settings.tab = "tomorrow";
            }
        });
    }

    componentDidMount() {
        if (!this.state.token) {
            return;
        }
        if (this.isWeekend()) {
            this.state.settings.tab = "tomorrow";
            this.setState(this.state);
        }

        this.getUser();

        // console.log('change tab DIDMOUNT', this.props.history.location)

        if (this.props.history.location.search === "?tab=today") {
            this.state.settings.tab = "today";
            this.setState(this.state);
        }
        if (this.props.history.location.search === "?tab=tomorrow") {
            this.state.settings.tab = "tomorrow";
            this.setState(this.state);
        }
    }

    componentDidUpdate(prevProps) {
        //this.getUser();
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }

    getSeatHighlight(part) {
        if (part === "morning") {
            if (this.user().booking.booking.morning === "4. Obergeschoss") {
                return this.user().booking.seats.find(function (element) {
                    return element.workplace_id === 2;
                }).id;
            }
            if (this.user().booking.booking.morning === "3. Obergeschoss") {
                return this.user().booking.seats.find(function (element) {
                    return element.workplace_id === 1;
                }).id;
            }
        }
        if (part === "afternoon") {
            if (this.user().booking.booking.afternoon === "4. Obergeschoss") {
                return this.user().booking.seats.find(function (element) {
                    return element.workplace_id === 2;
                }).id;
            }
            if (this.user().booking.booking.afternoon === "3. Obergeschoss") {
                return this.user().booking.seats.find(function (element) {
                    return element.workplace_id === 1;
                }).id;
            }
        }
    }

    user() {
        return this.state?.settings?.user;
    }

    getInitials(string) {
        var names = string.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }

    getSeatHighlightToday(part) {
        if (part === "morning") {
            if (this.user().today.booking.morning === "4. Obergeschoss") {
                return this.user().today.seats.find(function (element) {
                    return element.workplace_id === 2;
                }).id;
            }
            if (this.user().today.booking.morning === "3. Obergeschoss") {
                return this.user().today.seats.find(function (element) {
                    return element.workplace_id === 1;
                }).id;
            }
        }
        if (part === "afternoon") {
            if (this.user().today.booking.afternoon === "4. Obergeschoss") {
                return this.user().today.seats.find(function (element) {
                    return element.workplace_id === 2;
                }).id;
            }
            if (this.user().today.booking.afternoon === "3. Obergeschoss") {
                return this.user().today.seats.find(function (element) {
                    return element.workplace_id === 1;
                }).id;
            }
        }
    }

    hasComplicatedMapToday() {
        return (
            this.user() &&
            this.user().today &&
            this.state.settings.tab === "today" &&
            this.user().today.place === "it's complicated" &&
            (this.user().today.booking.morning === "4. Obergeschoss" ||
                this.user().today.booking.morning === "3. Obergeschoss" ||
                this.user().today.booking.afternoon === "4. Obergeschoss" ||
                this.user().today.booking.afternoon === "3. Obergeschoss")
        );
    }
    hasComplicatedMap() {
        return (
            this.user() &&
            this.user().booking &&
            this.state.settings.tab !== "today" &&
            this.user().booking.place === "it's complicated" &&
            (this.user().booking.booking.morning === "4. Obergeschoss" ||
                this.user().booking.booking.morning === "3. Obergeschoss" ||
                this.user().booking.booking.afternoon === "4. Obergeschoss" ||
                this.user().booking.booking.afternoon === "3. Obergeschoss")
        );
    }

    setActive(day) {
        this.state.settings.active = day;
        this.setState(this.state);

        let place =
            this.state.settings.user[
                this.state.settings.today ? "thisWeek" : "nextWeek"
            ][this.state.settings.active]?.place;

        if (place === "3. Obergeschoss" || place === "4. Obergeschoss") {
            this.state.settings.map = true;
        } else {
            this.state.settings.map = false;
        }
    }

    getUser() {
        let vm = this;

        axios
            .get("https://fi-boarding.h-och.ch/api/v1/maps", {
                headers: {
                    Authorization: "Bearer " + this.state.token.access_token,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                this.state.settings.maps = res.data.maps;
                this.state.settings.user = res.data.user;
                this.state.settings.loading = false;
                this.state.settings.success = true;

                if (
                    res.data.user.current_day >= 1 &&
                    res.data.user.current_day <= 5
                ) {
                    this.state.settings.active = res.data.user.current_day;
                }

                if (res.data.user.current_day > 5) {
                    this.state.settings.tab = "tomorrow";
                    this.state.settings.active = 1;
                }

                this.setState(this.state);
                console.log("this.state", this.state);
            })
            .catch(function (error) {
                console.log(error);
                if (error.response && error.response.status === 401) {
                }
                vm.state.settings.error = true;
                window.localStorage.removeItem("token");
                vm.setState(vm.state);
            });
    }
    getTodaysID() {
        if (this.user().today.place === "3. Obergeschoss") {
            return 0;
        }

        if (this.user().today.place === "4. Obergeschoss") {
            return 1;
        }
    }

    getTomorrowsId() {
        if (this.user().booking.place === "3. Obergeschoss") {
            return 0;
        }

        if (this.user().booking.place === "4. Obergeschoss") {
            return 1;
        }
    }

    selectSeat(seat) {
        if (!seat.enabled) {
            return;
        }
        if (seat.booking.length > 0) {
            return;
        }

        this.state.settings.user[
            this.state.settings.today ? "thisWeek" : "nextWeek"
        ][this.state.settings.active].seat = seat;

        this.setState(this.state);
        //this.setState({ booking: { seat: seat } });
    }

    setPlace(place) {
        // console.log(place)
        this.state.booking.place = place;
        this.setState(this.state);
    }

    isWeekend() {
        var today = new Date();
        if (today.getDay() === 6 || today.getDay() === 0) {
            return true;
        }
        return false;
    }

    getToday() {
        return "Diese Woche";
    }

    getTomorrow() {
        var today = new Date();

        if (
            today.getDay() === 6 ||
            today.getDay() === 5 ||
            today.getDay() === 0
        ) {
            return "Nächste Woche";
        }

        return "Nächste Woche";
    }

    getWeek() {
        return this.state.settings.today ? "thisWeek" : "nextWeek";
    }

    getHighlightedSeat(day) {
        if (day) {
            let seats = this.activeBooking().seats.filter((item) => {
                return item.pivot.settings == day;
            });
            if (seats.length > 0) {
                return seats[0].id;
            }
        }

        return this.state.settings.user[this.getWeek()][
            this.state.settings.active
        ]?.seats &&
            this.state.settings.user[this.getWeek()][this.state.settings.active]
                .seats.length > 0
            ? this.state.settings.user[this.getWeek()][
                  this.state.settings.active
              ].seats[0].id
            : null;
    }

    activeBooking() {
        if (
            this.state.settings.user &&
            this.state.settings.user[this.getWeek()]
        ) {
            return this.state.settings.user[this.getWeek()][
                this.state.settings.active
            ];
        }

        return null;
    }

    refresh = (event: CustomEvent<RefresherEventDetail>) => {
        this.getUser();
        setTimeout(() => {
            event.detail.complete();
        }, 500);
    };

    refreshMap(today) {
        this.getUser();
    }

    render() {
        if (!this.state.token || this.state.settings.error) {
            return <Redirect to="/login" />;
        }

        const user = this.user();

        return (
            <>
                <IonPage>
                    <IonContent>
                        <IonRefresher slot="fixed" onIonRefresh={this.refresh}>
                            <IonRefresherContent refreshingSpinner="dots"></IonRefresherContent>
                        </IonRefresher>

                        {user && (
                            <div className="senn-header">
                                <a className="senn-logo" href="/dashboard">
                                    <img
                                        src="/img/hoch.png"
                                        style={{ height: "100" }}
                                        width="100px"
                                    />
                                </a>
                                <span
                                    className="cursor-pointer uppercase font-bold mr-4"
                                    color={
                                        this.state.settings.tab === "today"
                                            ? "dark"
                                            : "medium"
                                    }
                                    onClick={() => {
                                        this.state.settings.tab = "today";
                                        this.state.settings.active =
                                            this.state.settings.user.current_day;
                                        this.setState(this.state);
                                    }}
                                >
                                    {this.getToday()}
                                </span>

                                <span
                                    className="cursor-pointer uppercase font-bold"
                                    color={
                                        this.state.settings.tab === "tomorrow"
                                            ? "dark"
                                            : "medium"
                                    }
                                    onClick={() => {
                                        this.state.settings.tab = "tomorrow";
                                        this.state.settings.active = 1;
                                        this.setState(this.state);
                                    }}
                                >
                                    {this.getTomorrow()}
                                </span>
                            </div>
                        )}

                        {!this.isEmpty(user) &&
                            !user.today &&
                            !this.isWeekend() && (
                                <IonCard
                                    className="no-radius"
                                    color="default"
                                    button={true}
                                    onClick={() =>
                                        this.props.history.push(
                                            "/buchung?edit=today"
                                        )
                                    }
                                >
                                    <IonCardHeader>
                                        <IonCardSubtitle>
                                            {this.getToday()}
                                        </IonCardSubtitle>
                                        <IonCardTitle>
                                            Noch kein Platz gebucht.
                                        </IonCardTitle>
                                        <IonIcon
                                            className="card-icon"
                                            icon={create}
                                        ></IonIcon>
                                    </IonCardHeader>
                                </IonCard>
                            )}

                        {!this.isEmpty(user) && !user.booking && (
                            <IonCard
                                className="no-radius"
                                color="default"
                                button={true}
                                onClick={() =>
                                    this.props.history.push(
                                        "/buchung?edit=tomorrow"
                                    )
                                }
                            >
                                <IonCardHeader>
                                    <IonCardSubtitle>
                                        {this.getTomorrow()}
                                    </IonCardSubtitle>
                                    <IonCardTitle>
                                        Noch kein Platz gebucht.
                                    </IonCardTitle>
                                    <IonIcon
                                        className="card-icon"
                                        icon={create}
                                    ></IonIcon>
                                </IonCardHeader>
                            </IonCard>
                        )}

                        {user &&
                            user.today &&
                            this.state.settings.tab === "today" && (
                                <IonCard
                                    className="no-radius"
                                    color="secondary"
                                    button={true}
                                    onClick={() =>
                                        this.props.history.push(
                                            "/buchung?edit=today"
                                        )
                                    }
                                >
                                    <IonCardHeader>
                                        <IonCardSubtitle>
                                            {this.getToday()}
                                        </IonCardSubtitle>
                                        <IonCardTitle className="card-title">
                                            <div className="card-title-seat">
                                                {this.state.settings.tab ===
                                                    "today" && (
                                                    <div>
                                                        {
                                                            user["thisWeek"][
                                                                this.state
                                                                    .settings
                                                                    .active
                                                            ]["titel"]
                                                        }
                                                    </div>
                                                )}
                                                {this.state.settings.tab !==
                                                    "today" && (
                                                    <div>
                                                        {
                                                            user[
                                                                "this_week_description"
                                                            ]
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        </IonCardTitle>
                                        <IonIcon
                                            className="card-icon"
                                            icon={create}
                                        ></IonIcon>
                                    </IonCardHeader>
                                </IonCard>
                            )}

                        {user &&
                            user.booking &&
                            this.state.settings.tab === "tomorrow" && (
                                <IonCard
                                    className="no-radius"
                                    color="secondary"
                                    button={true}
                                    onClick={() =>
                                        this.props.history.push(
                                            "/buchung?edit=tomorrow"
                                        )
                                    }
                                >
                                    <IonCardHeader>
                                        <IonCardSubtitle>
                                            {this.getTomorrow()}
                                        </IonCardSubtitle>
                                        <IonCardTitle className="card-title">
                                            <div className="card-title-seat">
                                                {this.state.settings.tab ===
                                                    "tomorrow" &&
                                                    user["nextWeek"][
                                                        this.state.settings
                                                            .active
                                                    ] && (
                                                        <div>
                                                            {
                                                                user[
                                                                    "nextWeek"
                                                                ][
                                                                    this.state
                                                                        .settings
                                                                        .active
                                                                ]["titel"]
                                                            }
                                                        </div>
                                                    )}
                                                {this.state.settings.tab !==
                                                    "tomorrow" && (
                                                    <div>
                                                        {
                                                            user[
                                                                "next_week_description"
                                                            ]
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        </IonCardTitle>
                                        <IonIcon
                                            className="card-icon"
                                            icon={create}
                                        ></IonIcon>
                                    </IonCardHeader>
                                </IonCard>
                            )}

                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "20px",
                                }}
                            >
                                <button
                                    disabled={
                                        this.state.settings.tab === "today" &&
                                        user?.current_day > 1
                                    }
                                    onClick={() => this.setActive(1)}
                                    color="light"
                                    style={{
                                        width: "20%",
                                        padding: "4px 8px",
                                        border:
                                            this.state.settings.active === 1
                                                ? "1px solid black"
                                                : "none",
                                        borderBottom:
                                            this.state.settings.active === 1
                                                ? "none"
                                                : "1px solid black",
                                        background:
                                            this.state.settings.active === 1
                                                ? "white"
                                                : "#f3f3f3",
                                    }}
                                >
                                    Mo
                                </button>
                                <button
                                    disabled={
                                        this.state.settings.tab === "today" &&
                                        user?.current_day > 2
                                    }
                                    onClick={() => this.setActive(2)}
                                    color="light"
                                    style={{
                                        width: "20%",
                                        padding: "4px 8px",
                                        border:
                                            this.state.settings.active === 2
                                                ? "1px solid black"
                                                : "none",
                                        borderBottom:
                                            this.state.settings.active === 2
                                                ? "none"
                                                : "1px solid black",
                                        background:
                                            this.state.settings.active === 2
                                                ? "white"
                                                : "#f3f3f3",
                                    }}
                                >
                                    Di
                                </button>
                                <button
                                    disabled={
                                        this.state.settings.tab === "today" &&
                                        user?.current_day > 3
                                    }
                                    onClick={() => this.setActive(3)}
                                    color="light"
                                    style={{
                                        width: "20%",
                                        padding: "4px 8px",
                                        border:
                                            this.state.settings.active === 3
                                                ? "1px solid black"
                                                : "none",
                                        borderBottom:
                                            this.state.settings.active === 3
                                                ? "none"
                                                : "1px solid black",
                                        background:
                                            this.state.settings.active === 3
                                                ? "white"
                                                : "#f3f3f3",
                                    }}
                                >
                                    Mi
                                </button>
                                <button
                                    disabled={
                                        this.state.settings.tab === "today" &&
                                        user?.current_day > 4
                                    }
                                    onClick={() => this.setActive(4)}
                                    color="light"
                                    style={{
                                        width: "20%",
                                        padding: "4px 8px",
                                        border:
                                            this.state.settings.active === 4
                                                ? "1px solid black"
                                                : "none",
                                        borderBottom:
                                            this.state.settings.active === 4
                                                ? "none"
                                                : "1px solid black",
                                        background:
                                            this.state.settings.active === 4
                                                ? "white"
                                                : "#f3f3f3",
                                    }}
                                >
                                    Do
                                </button>
                                <button
                                    disabled={
                                        this.state.settings.tab === "today" &&
                                        user?.current_day > 5
                                    }
                                    onClick={() => this.setActive(5)}
                                    color="light"
                                    style={{
                                        width: "20%",
                                        padding: "4px 8px",
                                        border:
                                            this.state.settings.active === 5
                                                ? "1px solid black"
                                                : "none",
                                        borderBottom:
                                            this.state.settings.active === 5
                                                ? "none"
                                                : "1px solid black",
                                        background:
                                            this.state.settings.active === 5
                                                ? "white"
                                                : "#f3f3f3",
                                    }}
                                >
                                    Fr
                                </button>
                            </div>
                        </div>

                        <div>
                            {user &&
                                this.activeBooking() &&
                                this.activeBooking().place == "Halbtage" &&
                                (this.activeBooking()?.settings?.morning ==
                                    "3. Obergeschoss" ||
                                    this.activeBooking().settings?.morning ==
                                        "4. Obergeschoss") && (
                                    <div>
                                        <p>Am Morgen:</p>
                                        <SeatMap
                                            viewOnly={false}
                                            today={this.state.settings.today}
                                            day={this.state.settings.active}
                                            user={this.state.settings.user}
                                            halfDay={"morning"}
                                            place={
                                                this.activeBooking().settings
                                                    ?.morning
                                            }
                                            seat={
                                                this.state.settings.user[
                                                    this.getWeek()
                                                ][this.state.settings.active]
                                                    ?.seat
                                            }
                                            highlight={this.getHighlightedSeat(
                                                "morning"
                                            )}
                                            selectSeat={this.selectSeat.bind(
                                                this
                                            )}
                                            refreshMap={this.refreshMap.bind(
                                                this
                                            )}
                                            booking={
                                                this.state.settings.user[
                                                    this.getWeek()
                                                ][this.state.settings.active]
                                            }
                                        />
                                    </div>
                                )}

                            {user &&
                                this.activeBooking() &&
                                this.activeBooking().place == "Halbtage" &&
                                (this.activeBooking()?.settings?.afternoon ==
                                    "3. Obergeschoss" ||
                                    this.activeBooking().settings?.afternoon ==
                                        "4. Obergeschoss") && (
                                    <div>
                                        <p>Am Nachmittag:</p>
                                        <SeatMap
                                            viewOnly={false}
                                            today={this.state.settings.today}
                                            day={this.state.settings.active}
                                            user={this.state.settings.user}
                                            place={
                                                this.activeBooking().settings
                                                    ?.afternoon
                                            }
                                            halfDay={"afternoon"}
                                            seat={
                                                this.state.settings.user[
                                                    this.getWeek()
                                                ][this.state.settings.active]
                                                    ?.seat
                                            }
                                            highlight={this.getHighlightedSeat(
                                                "afternoon"
                                            )}
                                            selectSeat={this.selectSeat.bind(
                                                this
                                            )}
                                            refreshMap={this.refreshMap.bind(
                                                this
                                            )}
                                            booking={
                                                this.state.settings.user[
                                                    this.getWeek()
                                                ][this.state.settings.active]
                                            }
                                        />
                                    </div>
                                )}
                        </div>

                        {user &&
                            user.today &&
                            this.state.settings.tab === "today" &&
                            (user["thisWeek"][this.state.settings.active]
                                ?.place === "3. Obergeschoss" ||
                                user["thisWeek"][this.state.settings.active]
                                    ?.place === "4. Obergeschoss") &&
                            this.state.settings.active != "week" && (
                                <IonCard className="welcome-card no-radius map-card">
                                    <IonCardContent>
                                        <div>
                                            <SeatMap
                                                viewOnly={true}
                                                today={true}
                                                day={this.state.settings.active}
                                                place={
                                                    this.state.settings.user[
                                                        "thisWeek"
                                                    ][
                                                        this.state.settings
                                                            .active
                                                    ]?.place
                                                }
                                                seat={
                                                    this.state.settings.user[
                                                        "thisWeek"
                                                    ][
                                                        this.state.settings
                                                            .active
                                                    ]?.seat
                                                }
                                                highlight={
                                                    this.state.settings.user[
                                                        "thisWeek"
                                                    ][
                                                        this.state.settings
                                                            .active
                                                    ]?.seats &&
                                                    this.state.settings.user[
                                                        "thisWeek"
                                                    ][
                                                        this.state.settings
                                                            .active
                                                    ].seats.length > 0
                                                        ? this.state.settings
                                                              .user["thisWeek"][
                                                              this.state
                                                                  .settings
                                                                  .active
                                                          ].seats[0].id
                                                        : null
                                                }
                                                selectSeat={this.selectSeat.bind(
                                                    this
                                                )}
                                                refreshMap={this.refreshMap.bind(
                                                    this
                                                )}
                                                booking={
                                                    this.state.settings.user[
                                                        "thisWeek"
                                                    ][
                                                        this.state.settings
                                                            .active
                                                    ]
                                                }
                                            />
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            )}

                        {user &&
                            user.booking &&
                            this.state.settings.tab === "tomorrow" &&
                            (user["nextWeek"][this.state.settings.active]
                                ?.place === "3. Obergeschoss" ||
                                user["nextWeek"][this.state.settings.active]
                                    ?.place === "4. Obergeschoss") &&
                            this.state.settings.active != "week" && (
                                <IonCard className="welcome-card no-radius map-card">
                                    <IonCardContent>
                                        <div>
                                            <SeatMap
                                                viewOnly={true}
                                                today={false}
                                                day={this.state.settings.active}
                                                place={
                                                    this.state.settings.user[
                                                        "nextWeek"
                                                    ][
                                                        this.state.settings
                                                            .active
                                                    ]?.place
                                                }
                                                seat={
                                                    this.state.settings.user[
                                                        "nextWeek"
                                                    ][
                                                        this.state.settings
                                                            .active
                                                    ]?.seat
                                                }
                                                highlight={
                                                    this.state.settings.user[
                                                        "nextWeek"
                                                    ][
                                                        this.state.settings
                                                            .active
                                                    ]?.seats &&
                                                    this.state.settings.user[
                                                        "nextWeek"
                                                    ][
                                                        this.state.settings
                                                            .active
                                                    ].seats.length > 0
                                                        ? this.state.settings
                                                              .user["nextWeek"][
                                                              this.state
                                                                  .settings
                                                                  .active
                                                          ].seats[0].id
                                                        : null
                                                }
                                                selectSeat={this.selectSeat.bind(
                                                    this
                                                )}
                                                refreshMap={this.refreshMap.bind(
                                                    this
                                                )}
                                                booking={
                                                    this.state.settings.user[
                                                        "nextWeek"
                                                    ][
                                                        this.state.settings
                                                            .active
                                                    ]
                                                }
                                            />
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            )}
                    </IonContent>
                </IonPage>
            </>
        );
    }
}
